@keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideDownContent {
    from {
      transform: translateY(-20vh);
    }
    to {
      transform: translateY(0);
    }
  }

.fa-solid.fa-rectangle-xmark{
    font-size: 150%;


}

  