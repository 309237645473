/* Custom styles for react-date-picker */
.react-date-picker {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.react-date-picker__wrapper {
    padding: 0;
    border: none;
}

.react-date-picker__inputGroup__input {
    font-size: 14px;
    border: none;
    outline: none;
    font-family: 'Popins', sans-serif;
}

.react-calendar {
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Popins', sans-serif;
}

.react-date-picker__clear-button,
.react-date-picker__calendar-button {
    display: none;
}
