/* Thêm class .login-page để bao bọc phần tử login */
.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  /* Màu nền để dễ nhìn */
}

.login-page .wrapper {
  width: 420px;
  background: white;
  border-radius: 10px;
  padding: 30px 40px;
}

.login-page .wrapper h1 {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.login-page .wrapper .input-box {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 30px 0;
}

.login-page .input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, .2);
  border-radius: 40px;
  border: 2px solid #000;
  padding: 20px 45px 20px 20px;
}

.login-page .input-box input::placeholder {
  color: #515A78;
}

.login-page .input-box .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.login-page .wrapper .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -15px 0 15px;
}

.login-page .remember-forgot label input {
  accent-color: black;
  margin-right: 4px;
}

.login-page .remember-forgot a {
  color: black;
  text-decoration: none;
}

.login-page .remember-forgot a:hover {
  text-decoration: underline;
}

.login-page .wrapper button {
  width: 100%;
  height: 45px;
  background: #A2B8FF;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 700;
}
