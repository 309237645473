/* CustomModal.css */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.custom-modal-overlay.open {
    opacity: 1;
    visibility: visible;
}

.custom-modal-content {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    max-width: 400px;
    width: 100%;
    text-align: center;
    transform: scale(0.9);
    transition: transform 0.3s ease;
}


.custom-modal-overlay.open .custom-modal-content {
    transform: scale(1);
}

.custom-button {
    padding: 10px 30px;
    /* Tăng padding ngang */
    margin: 5px;
    /* Giảm margin để cân đối */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    /* Giảm font-size */
}

.custom-button-confirm {
    background-color: #28a745;
    color: white;
    transition: background-color 0.3s ease;
}

.custom-button-confirm:hover {
    background-color: #218838;
}

.custom-button-cancel {
    background-color: #dc3545;
    color: white;
    transition: background-color 0.3s ease;
}

.custom-button-cancel:hover {
    background-color: #c82333;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .custom-modal-content {
        width: 100%;
        /* Full width on smaller screens */
        padding: 1rem;
        /* Reduced padding on smaller screens */
    }

    .custom-button {
        font-size: 12px;
        /* Smaller font size on smaller screens */
        padding: 8px 16px;
        /* Adjusted padding on smaller screens */
    }

    .custom-button-container {
        display: flex;
        justify-content: space-around;
        margin-top: 15px;
    }
}

.message {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}