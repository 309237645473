.user-profile {
    width: 60%;
    margin: 20px auto;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.profile-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.profile-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
}

.form-group label {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    color: #555;
    width: 150px;
    text-align: left;
}

.form-group input {
    flex: 1 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.form-group input:focus {
    border-color: #007bff;
    outline: none;
}

.form-group input[type="checkbox"] {
    flex: 0 0;
    margin: 0;
    transform: scale(1.2);
}

.submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}

.loading {
    text-align: center;
    font-size: 18px;
    color: #666;
}