* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    width: 100%;
    font-family: Arial, sans-serif;
}

.mainBox {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #f8f9fa;
}

.leftBox {
    width: 250px;
    background-color: #343a40;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.Logo {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
}

/* .btn {
    background: none;
    border: none;
    color: #ffffff;
    padding: 10px;
    text-align: left;
    font-size: 1em;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.btn.active,
.btn:hover {
    background-color: #495057; */
/* } */

/* Các nút chính */
/* Nút chính */
.btn {
    background: none;
    border: none;
    color: #ffffff;
    padding: 10px;
    text-align: left;
    font-size: 1em;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 4px; /* Bo góc mềm mại */
}

.btn.active,
.btn:hover {
    background-color: #495057; /* Màu xám tối hơn cho trạng thái active và hover */
    color: #ffffff; /* Giữ màu chữ trắng */
}

/* Nút con (submenu) */
.btn-sub {
    background: none;
    border: none;
    color: #adb5bd; /* Màu chữ nhạt hơn cho submenu */
    padding: 8px;
    margin: 3px 0 0 20px; /* Thụt lề submenu */
    font-size: 0.9em;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 4px;
}

.btn-sub.active,
.btn-sub:hover {
    background-color: #6c757d; /* Màu xám trung tính cho active và hover submenu */
    color: #ffffff; /* Màu chữ trắng */
}

.sidebar{
    width: 250px; /* Chiều rộng của thanh bên */
    height: 100vh; /* Chiều cao toàn màn hình */
    background-color: #343a40; /* Màu nền */
    overflow-y: auto; /* Kích hoạt cuộn dọc */
    overflow-x: hidden; /* Ẩn cuộn ngang nếu có */
    padding: 10px; /* Khoảng cách bên trong */
    box-sizing: border-box; /* Bao gồm padding vào kích thước */
}

.footer {
    margin-top: auto;
}

.footer-content {
    display: flex;
    align-items: center;
}

.rightBox {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.search-container {
    display: flex;
    align-items: center;
}

.searchForm {
    display: flex;
    align-items: center;
}

.searchForm input {
    padding: 5px 10px;
    border: 1px solid #ced4da;
    border-radius: 3px 0 0 3px;
    outline: none;
}

.searchForm button {
    padding: 5px 10px;
    border: 1px solid #ced4da;
    background-color: #343a40;
    color: #ffffff;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.data-table th,
.data-table td {
    border: 1px solid #dee2e6;
    padding: 10px;
    text-align: left;
}

.data-table th {
    background-color: #f1f1f1;
}

.editButton {
    padding: 5px 10px;
    background-color: #17a2b8;
    color: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    display: inline-block;
}

.editButton:hover {
    background-color: #138496;
}

.modal {
    display: none;
    position: fixed;
    /* Stay in place */
    z-index: 1000;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    /* Centered */
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    max-width: 600px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-footer {
    justify-content: center;
    display: flex;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.formGroup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.formButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.updateButton,
.deleteButton {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.updateButton {
    background-color: #1abc9c;
}

.deleteButton {
    background-color: #e74c3c;
}


.rightPart {
    display: flex;
    align-items: center;
}

.search-container {
    margin-left: auto;
}

.addButton {
    background-color: #1abc9c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.addButton:hover {
    background-color: #16a085;
}

.modal {
    display: flex;
    /* Hoặc flexbox để căn giữa */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Để tạo nền mờ */
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Đảm bảo modal nổi bật hơn các phần khác */
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    max-width: 600px;
    border-radius: 8px;
    position: relative;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    font-weight: bold;
}

.formGroup input[type="text"],
.formGroup input[type="password"] {
    width: calc(100% - 20px);
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.formGroup input[type="checkbox"] {
    width: 20px;
    /* Adjust width of checkbox */
    height: 20px;
    /* Adjust height of checkbox */
    margin-right: 10px;
    /* Add margin for spacing */
}

.formButtons {
    margin-top: 20px;
    text-align: center;
}

.updateButton,
.submitButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.updateButton:hover,
.submitButton:hover {
    background-color: #45a049;
}

.addFormButtons button {
    background-color: #008CBA;
}

.addFormButtons button:hover {
    background-color: #0073aa;
}

.modal-content h2 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
    .modal-content {
        width: 90%;
    }
}

.userNameAdmin {
    margin-left: 10px;
    margin-right: 40px;
    font-size: 30px;
}

.iconAccount {
    font-size: 30px;
}

.iconLogOut {
    font-size: 30px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.iconLogOut:hover {
    color: red;
    /* Thay đổi màu sắc của icon khi hover */
}

.tdbutton {
    text-align: center;
    vertical-align: middle;
}

.formSelect {
    width: 96.5%;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.formSelect:focus {
    border-color: #66afe9;
    outline: none;
    box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}

.formSelect:hover {
    border-color: #888;
}

.title {
    background-color: red;
}

/* Đặt modal ở giữa màn hình */
.confirm-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    padding: 20px;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
}

/* Nội dung trong modal */
.confirm-content {
    margin-bottom: 20px;
}

/* Các nút trong modal */
.confirm-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.confirm-actions button {
    flex: 1;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

/* Nút "Lưu" (màu xanh) */
.confirm-actions button:first-child {
    background-color: #4caf50;
    color: white;
}

.confirm-actions button:first-child:hover {
    background-color: #45a049;
}

/* Nút "Quay lại" (màu xám nhạt) */
.confirm-actions button:last-child {
    background-color: #f1f1f1;
    color: #333;
}

.confirm-actions button:last-child:hover {
    background-color: #ddd;
}

/* Hiệu ứng fade-in */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

/* Nền mờ đằng sau modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}


.deleteButton {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.deleteButton:hover {
    background-color: #d9363e;
}


.TitleHeader {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.form-group select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    /* Viền giống input */
    border-radius: 4px;
    /* Bo góc nhẹ */
    font-size: 14px;
    color: #333;
    appearance: none;
    /* Ẩn các style mặc định của trình duyệt */
    background-color: #fff;
}