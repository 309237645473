.pagination {
    display: flex;
    justify-content: center;
    padding: 1rem;
    list-style-type: none;
}

.pagination li {
    margin: 0 0.5rem;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
}

.pagination li a {
    display: flex; /* Đảm bảo liên kết bao phủ toàn bộ vùng của li */
    align-items: center; /* Đảm bảo văn bản ở giữa theo chiều dọc */
    justify-content: center; /* Đảm bảo văn bản ở giữa theo chiều ngang */
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: inherit; /* Đảm bảo màu sắc được thừa kế */
}

.pagination li:hover {
    background-color: #ddd;
}

.pagination li.active a {
    font-weight: bold;
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}

.pagination .previous a,
.pagination .next a,
.pagination .break a {
    padding: 0.5rem 1rem; /* Tùy chỉnh kích thước padding để mở rộng vùng click */
    cursor: pointer; /* Đảm bảo con trỏ chuột thay đổi khi di chuột qua */
}

.pagination a {
    outline: none; /* Bỏ viền focus */
    user-select: none; /* Ngăn chọn văn bản */
    -webkit-tap-highlight-color: transparent; /* Ngăn chặn hiệu ứng highlight trên các thiết bị cảm ứng */
}
